/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select'
import { useParams } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	BASEURL,
	getJobStatus,
	getJobStatusClass,
	moneyFormatter,
	phoneNumberFormatter,
} from '../../../utilites';
import DatePicker from 'react-datepicker';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Header from '../../dashboard/components/Header';
import Footer from '../../dashboard/components/Footer';
import Modal from '../../../components/Dashboard/Modal';
import CustomerQuestionnaires from '../../dashboard/leads/components/CustomerQuestionnaires';
import CustomerMessageBoard from '../../dashboard/leads/components/CustomerMessageBoard';
import JobDocuments from '../../dashboard/components/JobDocuments';
import CustomerInvoices from '../../dashboard/leads/components/CustomerInvoices';
import Preloader from '../components/Preloader';
import allActions from '../../../Store/action';
import { INVOICE_ACTION_TYPE } from '../../../Store/invoice/Type';
import Message from '../../../components/forms/fields/Message';
import Button from '../../../components/forms/fields/Button';
import JobStatusUpdateForm from '../../../components/forms/JobStatusUpdateForm';
import JobRequestForm from '../../../components/forms/JobRequestForm';
import AddDisputeForm from '../../../components/forms/AddDisputeForm';
import GetHelpForm from '../../../components/forms/GetHelpForm';
import { FaEdit } from 'react-icons/fa';
import CompleteJobStepPopup from '../../../components/forms/Modal/CompleteJobStepPopup';
import AddJobForm from '../../../components/forms/AddJobForm';
import JobNotes from './JobNotes';

const JobDetails = () => {
	const [{ LoginData }, { createInvoice, createInvoiceMessage }, { professionals }] = useSelector(
		(state) => [state.user, state.invoice, state.dashboard]
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { uuid } = useParams();
	// useEffect(()=>{
	// 	console.log("UUID@@@", uuid);
	// }, [])
	const [lead, setLead] = useState(null);
	const [message, setMessage] = useState({ show: false });
	const [invoiceDeadline,  setInvoiceDedline] = useState();
	const [handler, setHandler] = useState(false);
	const [unlinkedInvoices,setUnlinkedInvoices] = useState([])
	const [selectedInvoice,setSelectedInvoice] = useState(null)
	const [defaultSelection, setDefaultSelection] = useState(false);

	const job = lead?.jobs[0];
	let paidAmount = 0;
	let remainingAmount = 0;
	job?.invoices?.map((data) =>
		data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)))
	);

	job?.invoices?.map((data) => (remainingAmount = remainingAmount + parseFloat(data.amount)));
	useEffect(() => {
		if (LoginData.isAdmin || LoginData.isAccountManager) {
			dispatch(allActions.dashboard.getTaxProfessionals());
		}
	}, [LoginData.isAdmin, LoginData.isAccountManager]);





	// const totalCostCalulations = (invoices,projectCost) => {
	// 	let cost = 0;
	// 	if(invoices?.length===0){
	// 		cost=projectCost
	// 	}else{	
	// 	invoices?.forEach((element) => {
	// 		if (!element?.name?.includes('subscription')) {
	// 			cost += Number(element?.amount);
	// 		}
	// 	});
	// }
	// 	return cost;
	// };

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	  };
	
	const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				console.log('job data>>>>', res.data);
				setLead(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	const fetchUnlinkeInvoices = async () => {
		try {			
			const resp  = await axios.get(`${BASEURL}/api/invoices/unlinked?job_id=${job?.id}&lead_id=${lead?.id}`)
			setUnlinkedInvoices(resp?.data?.data?.invoices)
		} catch (error) {
			console.log('error>>>>', error);
			
		}
	}



	useEffect(() => {
		loadJobByUUID(uuid);
		reset_states();
	}, [uuid, loadJobByUUID]);

	useEffect(() => {
		if (state?.message) {
			console.log('state?.message', state?.message);
			setMessage({
				show: true,
				type: 'Success',
				text: state?.message,
			});
		}
		navigate(`/dashboard/jobs/${uuid}`, { replace: true });
	}, [navigate]);

	// ******************************
	// HOOKS FOR ADD INVOICES
	// ******************************

	useEffect(() => {
		if (!createInvoice && handler) {
			document.querySelector('.requestbalancepaymentInfoModalClose').click();
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
		}

		if (createInvoice === true && handler) {
			setMessage({
				show: true,
				type: 'Success',
				text: 'Generate Invoice Successfully!',
			});
			document.querySelector('.requestbalancepaymentInfoModalClose').click();
			dispatch(allActions.global.Loader(false));
			loadJobByUUID(uuid);
		}
	}, [createInvoice, createInvoiceMessage]);

	const generateRemaningDueInvoice = (e) => {
		e.preventDefault();
		let invoiceObj = {
			user_id: lead?.user?.id,
			dueDate: moment(new Date(invoiceDeadline), 'YYYY-MM-DD').format('MM/DD/YYYY'),
			name: job?.service?.name || job.others,
			amount: job?.invoice_data?.remainingAmount,
			job_id: job?.id,
			pro_id: LoginData?.userpro.id,
		};
		dispatch(allActions.global.Loader(true));
		reset_states();
		setHandler(true);
		dispatch(allActions.invoice.createInvoice(invoiceObj, job?.leads?.uuid));
	};

	const reset_states = () => {
		setHandler(false);
		setMessage({ show: false });
		dispatch(allActions.invoice.resetCreateInvoice());
		dispatch({ type: INVOICE_ACTION_TYPE.invoiceStatusIdle });
	};

	const unlinkedInvoicesOptions = unlinkedInvoices?.length > 0 ? unlinkedInvoices.map(invoice => ({
		value: invoice?.id,
		label: invoice?.name +"  $"+invoice?.amount
	})) : [];

	// eslint-disable-next-line no-unused-vars
	const handleLinkInvoice = async () => {
		const invoiceId = selectedInvoice?.value
		const jobId = job?.id
		if (selectedInvoice) {
			changeLoading(true)
			try {
				axios.patch(`${BASEURL}/api/invoices/${invoiceId}/${jobId}/link?markas=${defaultSelection}`)
				document.querySelector('.invoiceModal').click()
				setMessage({
					show: true,
					type: 'Success',
					text: 'Invoice successfully linked to this job.',
				});
				changeLoading(false)
				setSelectedInvoice(null)
				dispatch(allActions.invoice.getJobInvoices(job?.uuid));
				setTimeout(() => {
					fetchUnlinkeInvoices()
					setMessage({
						show: false,
					});
				}, 1000)
			} catch (error) {
				console.log(error)
			}
		}
	}
	const [selectedTab, setSelectedTab] = useState('jobs-tab');

	const handleTabClick = (tabId) => {
		setSelectedTab(tabId);
	};
	
	if (lead) {
		return (
			<div className="clearfix">
				<Header
					name="Jobs"
					enableBreadcrumb={true}
					dropdownContent={
						LoginData.isTaxPro ? (
							<>
								<li>
									<div
										className="dropdown-item"
										data-bs-toggle="modal"
										data-bs-target="#jobAdditionForm"
									>
										Create New Job
									</div>
								</li>
								<li>
									<div
										className="dropdown-item"
										data-bs-toggle="modal"
										data-bs-target="#CompleteJobStepPopup"
									>
										Complete Job
									</div>
								</li>
								<li>
									<div
										className="dropdown-item"
										data-bs-toggle="modal"
										data-bs-target="#jobCanelRequestForm"
									>
										Cancel Job
									</div>
								</li>
								<li>
									<Link
										className="dropdown-item"
										to="https://help.unclekam.com/en"
										target="_blank"
									>
										Get help
									</Link>
								</li>
							</>
						) : null
					}
				/>
				<div className="container page">
					{message?.show ? <Message message={message} /> : <></>}
					<div className="clearfix pt-4 mb-4">
						<h5 className="fw-bold">{`${lead.user.firstname} ${lead.user.lastname}`}</h5>
					</div>
					<div className="clearfix">
					<ul className="d-flex list-unstyled p-0 m-0" id="myTab" role="tablist">
					<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'jobs-tab' ? 'active' : ''}`}
									id="jobs-tab"
									data-bs-toggle="tab"
									data-bs-target="#jobs-tab-pane"
									type="button"
									role="tab"
									aria-controls="jobs-tab-pane"
									aria-selected={selectedTab === 'jobs-tab'}
									onClick={() => handleTabClick('jobs-tab')}
								>
									Details
									{selectedTab === 'jobs-tab' && <span className="underline" />}
								</button>
								
							</li>
							{!LoginData.isTaxPro && (
								<li className="custom-tab-item" role="presentation">
									<button
										className={`custom-tab-link ${selectedTab === 'details-tab' ? 'active' : ''}`}
										id="details-tab"
										data-bs-toggle="tab"
										data-bs-target="#details-tab-pane"
										type="button"
										role="tab"
										aria-controls="details-tab-pane"
										aria-selected={selectedTab === 'details-tab'}
										onClick={() => handleTabClick('details-tab')}
									>
										Contact
										{selectedTab === 'details-tab' && <span className="underline" />}
									</button>
								</li>
							)}
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'questionaires-tab' ? 'active' : ''}`}
									id="questionaires-tab"
									data-bs-toggle="tab"
									data-bs-target="#questionaires-tab-pane"
									type="button"
									role="tab"
									aria-controls="questionaires-tab-pane"
									aria-selected={selectedTab === 'questionaires-tab'}
									onClick={() => handleTabClick('questionaires-tab')}
								>
									Questionnaire
									{selectedTab === 'questionaires-tab' && <span className="underline" />}
								</button>
							</li>
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'messages-tab' ? 'active' : ''}`}
									id="messages-tab"
									data-bs-toggle="tab"
									data-bs-target="#messages-tab-pane"
									type="button"
									role="tab"
									aria-controls="messages-tab-pane"
									aria-selected={selectedTab === 'messages-tab'}
									onClick={() => handleTabClick('messages-tab')}
								>
									Messages
									{selectedTab === 'messages-tab' && <span className="underline" />}
								</button>
							</li>
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'documents-tab' ? 'active' : ''}`}
									id="documents-tab"
									data-bs-toggle="tab"
									data-bs-target="#documents-tab-pane"
									type="button"
									role="tab"
									aria-controls="documents-tab-pane"
									aria-selected={selectedTab === 'documents-tab'}
									onClick={() => handleTabClick('documents-tab')}
								>
									Documents & Forms
									{selectedTab === 'documents-tab' && <span className="underline" />}
								</button>
							</li>
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'invoices-tab' ? 'active' : ''}`}
									id="invoices-tab"
									data-bs-toggle="tab"
									data-bs-target="#invoices-tab-pane"
									type="button"
									role="tab"
									aria-controls="invoices-tab-pane"
									aria-selected={selectedTab === 'invoices-tab'}
									onClick={() => handleTabClick('invoices-tab')}
								>
									Invoices
									{selectedTab === 'invoices-tab' && <span className="underline" />}
								</button>
							</li>
							{(LoginData?.roles[0].name === 'taxpro' ||
								LoginData?.roles[0].name === 'support' ||
								LoginData?.roles[0].name === 'admin' ||
								LoginData?.roles[0].name === 'manager') && (
									<li className="custom-tab-item" role="presentation">
										<button
											className={`custom-tab-link ${selectedTab === 'notes-tab' ? 'active' : ''}`}
											id="notes-tab"
											data-bs-toggle="tab"
											data-bs-target="#notes-tab-pane"
											type="button"
											role="tab"
											aria-controls="notes-tab-pane"
											aria-selected={selectedTab === 'notes-tab'}
											onClick={() => handleTabClick('notes-tab')}
										>
											Notes
											{selectedTab === 'notes-tab' && <span className="underline" />}
										</button>
									</li>
								)}
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="jobs-tab-pane"
								role="tabpanel"
								aria-labelledby="jobs-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<div className="card-header d-flex justify-content-between bg-white border-0">
										<h1 className="text-primary mb-3">Summary</h1>
										<div className="card-actions">
											<Link
												className={`dropdown-item${
													job.status_id > 1 && job.status_id !== 5 ? ' disabled' : ''
												}`}
												to={`/dashboard/jobs/edit/${job.uuid}`}
												onClick={() => {
													dispatch(allActions.dashboard.resetTaxPlanning());
													dispatch(allActions.dashboard.resetTaxPreparation());
												}}
											>
												<FaEdit className="text-grey-500" />
											</Link>
										</div>
									</div>
									<p>
										Status:{' '} 
										<span
											className={`ms-3 text-${getJobStatusClass(job.status_id)}${
												!LoginData.isAdmin && !LoginData.isAccountManager
													? ' pe-none'
													: ' cursor-pointer'
											}`}
											data-bs-toggle="modal"
											data-bs-target="#updateJobStatusModal"
										>
											{getJobStatus(job.status_id)}
										</span>
									</p>
									<p>
										Service Type: <span className="ms-3">{job?.service?.name || job.others}</span>
									</p>
									{!LoginData.isTaxPro && (
										<p>
											Assigned To:{' '}
											<Link to={`/dashboard/users/${lead.userpro.user.uuid}`} className="d-inline-block text-underline-none ms-3">
												{`${lead.userpro?.user?.firstname} ${lead.userpro?.user?.lastname}`}
											</Link>
										</p>
									)}
									<p>
										Assigned On:{' '}
										<span className="ms-3">
											{new Date(job.created_at).toLocaleString('en-US', {
												month: 'long',
												day: 'numeric',
												year: 'numeric',
											})}
										</span>
									</p>
									{/* temp comment  */}
									{/* {LoginData?.isAdmin && <button  onClick={()=>{fetchUnlinkeInvoices()}} className='btn btn-primary'>
									<Link
										to="#"
										className={`dropdown-item text-light invoiceModal`}
										data-bs-toggle="modal"
										data-bs-target="#linkInvoice"
									>
										Link Invoice
									</Link>
									</button>} */}
								</div>
								{job.status_id !== 5 && (
									<div className="clearfix border border-light rounded-3 p-4 mt-4">
										<div className="card-header d-flex justify-content-between bg-white border-0">
											<h1 className="text-primary mb-3">Terms</h1>
											<div className="card-actions">
												<div className="dropdown-center">
													<button
														type="button"
														className="btn btn-sm text-primary rounded-circle"
														data-bs-toggle="dropdown"
														aria-expanded="false"
													>
														<FaEdit className="text-grey-500" />
													</button>
													<ul className="dropdown-menu border-light">
														<li>
															<Link
																className={`dropdown-item${
																	job.status_id > 1 && job.status_id !== 5 ? ' disabled' : ''
																}`}
																to={`/dashboard/jobs/edit/${job.uuid}`}
																onClick={() => {
																	dispatch(allActions.dashboard.resetTaxPlanning());
																	dispatch(allActions.dashboard.resetTaxPreparation());
																}}
															>
																Edit
															</Link>
														</li>
														{LoginData.isTaxPro && job.status_id > 0 && (
															<li>
																<Link
																	to="#"
																	className={`dropdown-item text-danger`}
																	data-bs-toggle="modal"
																	data-bs-target="#jobCanelRequestForm"
																>
																	Request to Cancel
																</Link>
															</li>
														)}
													</ul>
												</div>
											</div>
										</div>
										<p>
											Completion Date:{' '}
											<span className="ms-3">
												{new Date(job.completed_at).toLocaleString('en-US', {
													month: 'long',
													day: 'numeric',
													year: 'numeric',
												})}
											</span>
										</p>
										<p>
											Total Cost: <span className="ms-3">{moneyFormatter(job.cost)}</span>
										</p>
										<p>
											Deposit:{' '}
											<span className="ms-3">
												${paidAmount ? paidAmount : 0}{' '}
												{job.invoice_type === 'partial' &&
													' (' + Number((paidAmount / job?.cost) * 100).toFixed(2) + '%)  '}
												<span className={`badge bg-${paidAmount === 0 ? 'secondary-500' : 'dark'}`}>
													{paidAmount === 0 ? 'Unpaid' : 'Paid'}
												</span>
											</span>
										</p>
										{job.invoice_type === 'partial' &&
											LoginData.isTaxPro &&
											paidAmount > 0 &&
											job.cost - remainingAmount > 0 && (
												<button
													className={`btn btn-outline-primary px-5 rounded-5 ms-4 ${
														job.status_id === 4 && 'disabled'
													}`}
													data-bs-toggle="modal"
													data-bs-target="#requestbalancepayment"
												>
													Request Balance
												</button>
											)}{' '}
										{job.status_id === 4 && (
											<span className="text-danger">
												Once job is canceled, it can no longer be charged
											</span>
										)}
									</div>
								)}
								<div
									className="tab-pane "
									// id="notes-tab-pane"
									// role="tabpanel"
									// aria-labelledby="notes-tab"
									tabIndex="0"
								>
									{
										(LoginData?.roles[0].name === 'taxpro' ||
											LoginData?.roles[0].name === 'support' ||
											LoginData?.roles[0].name === 'admin' ||
											LoginData?.roles[0].name === 'manager')
										&& <div className="clearfix border border-light rounded-3 p-4 mt-4">
											<h1 className="text-primary">Notes</h1>
											{/* <CustomerInvoices activeLead={lead} uuid={uuid} /> */}
											<JobNotes job={job} />
										</div>
									}
								</div>
							</div>
							{!LoginData.isTaxPro && (
								<div
									className="tab-pane fade"
									id="details-tab-pane"
									role="tabpanel"
									aria-labelledby="details-tab"
									tabIndex="0"
								>
									<div className="clearfix border border-light rounded-3 p-4 mt-4">
										<div className="card-header d-flex justify-content-between bg-white border-0">
											<h1 className="text-primary mb-4">Contact</h1>
											<div className="card-actions">
												<div className="dropdown-center">
													<button
														type="button"
														className="btn btn-sm text-primary rounded-circle"
														data-bs-toggle="dropdown"
														aria-expanded="false"
													>
														<BsThreeDotsVertical className="" />
													</button>
													<ul className="dropdown-menu border-light">
														<li>
															<Link
																className={`dropdown-item`}
																to={`/dashboard/jobs/edit/${job.uuid}`}
															>
																Edit mklmklmlk
															</Link>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="clearfix">
											<div className="clearfix">
												<p>
													<strong>First Name:</strong>{' '}
													<Link to={'/dashboard/users/' + lead.user.uuid} className="text-decoration-none">
														{lead.user.firstname + ' ' + lead.user.lastname}
													</Link>
												</p>
												<p>
													<strong>Email:</strong>{' '}
													<Link
														to={`mailTo: ${lead.user.email}`}
														className="text-decoration-none text-dark"
													>
														{lead.user.email}
													</Link>
												</p>
												<p>
													<strong>Phone:</strong>{' '}
													<Link
														to={`tel: ${phoneNumberFormatter(lead.phone)}`}
														className="text-decoration-none text-dark"
													>
														{phoneNumberFormatter(lead.phone)}
													</Link>
												</p>
												<p>
													<strong>Address:</strong> {lead.street ?? 'N/A'} {lead.street2 ?? ''}
												</p>
												<p>
													<strong>City:</strong> {lead.city}
												</p>
												<p>
													<strong>State:</strong> {lead.state}
												</p>
												<p>
													<strong>Zip:</strong> {lead.zipcode}
												</p>
											</div>
										</div>
									</div>
								</div>
							)}
							<div
								className="tab-pane fade"
								id="questionaires-tab-pane"
								role="tabpanel"
								aria-labelledby="questionaires-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Questionnaire</h1>
									<CustomerQuestionnaires data={lead} />
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="messages-tab-pane"
								role="tabpanel"
								aria-labelledby="messages-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Messages</h1>
									<CustomerMessageBoard
										uuid={lead?.user?.uuid}
										group={
											(LoginData?.roles[0]?.name === 'admin' ||
												LoginData?.roles[0]?.name === 'accountmanager') && [
												lead?.userpro?.user?.uuid,
												lead?.user?.uuid,
											]
										}
										editable={
											LoginData?.roles[0]?.name === 'admin' ||
											LoginData?.roles[0]?.name === 'accountmanager'
												? false
												: true
										}
									/>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="documents-tab-pane"
								role="tabpanel"
								aria-labelledby="documents-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<div className="p-4">
										<JobDocuments
											data={{ ...job, ...{ user: lead.user } }}
											reload={() => loadJobByUUID(uuid)}
											documentRequestData={{
												job_id: job.id,
												pro_id: LoginData?.userpro?.id,
												customer_id: lead?.user?.id,
												isTaxPro: LoginData.isTaxPro,
											}}
											documentRequestLeadData={lead?.user}
											isTaxPreparation={job?.service?.id === 1 ? true : false}
											leadUUID={lead?.uuid}
											leadID={lead?.id}
											taxProUserId={lead?.userpro?.user?.id || LoginData?.id}
										/>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="invoices-tab-pane"
								role="tabpanel"
								aria-labelledby="invoices-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Invoices</h1>
									<CustomerInvoices activeLead={lead} uuid={uuid} selectedJob={job} />
								</div>
							</div>
							{(LoginData?.roles[0].name === 'taxpro' ||
								LoginData?.roles[0].name === 'support' ||
								LoginData?.roles[0].name === 'admin' ||
								LoginData?.roles[0].name === 'manager')
								&&
								<div
									className="tab-pane fade"
									id="notes-tab-pane"
									role="tabpanel"
									aria-labelledby="notes-tab"
									tabIndex="0"
								>
									<div className="clearfix border border-light rounded-3 p-4 mt-4">
										<h1 className="text-primary">Notes</h1>
										<JobNotes job={job} />
									</div>
								</div>}
						</div>
					</div>
				</div>
				{/* Modal starts here */}
				<Modal
				     	title="Create a new job request"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="jobAdditionForm"
						showBtn={false}
						btnClass="jobAdditionModalClose"
					>
						<AddJobForm />
					</Modal>
				{/*  Modal ends here*/}
				<Modal
					title="Request Balance Payment"
					bodyClass="p-4"
					btnText="Request Balance"
					trigger="requestbalancepayment"
					showBtn={false}
					btnClass="requestbalancepaymentInfoModalClose"
				>
					<div className="clearfix p-2">
						<form className="clearfix w-100" onSubmit={(e) => generateRemaningDueInvoice(e)}>
							<div className="text-dark w-100 text-align-left my-1">Invoice expiry date</div>
							<div className="mb-2">
								<DatePicker
									className="form-control"
									wrapperClassName="d-block"
									selected={invoiceDeadline}
									onChange={(date) => setInvoiceDedline(date)}
									dateFormat="MM/dd/yyyy"
									placeholderText="mm/dd/yyyy"
									minDate={new Date()}
									required
								/>
							</div>
							<Button
								mainClass="btn btn-primary text-white w-100"
								wrapperClass={'w-75 custon-button mx-auto mt-4'}
								buttonText={'Request Balance'}
							></Button>
						</form>
					</div>
				</Modal>
				<Modal
					title="Job Cancel Request"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="jobCanelRequestForm"
					showBtn={false}
					btnClass="jobCanelRequestModalClose"
				>
					<JobRequestForm 
						type={0}
						triggerClass=".jobCanelRequestModalClose"
						setMessage={setMessage}
						uuid={uuid}
						/>
				</Modal>
				<Modal
					title="Link Invoice"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="linkInvoice"
					showBtn={false}
					btnClass="linkInvoiceModalClose"
				>
					<ReactSelect
						id="state"
						className="p-0 text-muted text-start"
						placeholder="State"
						closeMenuOnSelect={true}
						value={selectedInvoice}
						options={
							unlinkedInvoicesOptions
						}
						onChange={setSelectedInvoice}
					/>
					
						<div className="my-3 col-lg-12 col-sm-12">
						<div className="w-full d-flex align-items-start justify-content-start">
							<input
								className="form-check-input custom-checkbox pl-2 "
								type="checkbox"
								id={`checkbox`}
								checked={defaultSelection}
								onChange={() => setDefaultSelection(!defaultSelection)}
							/>
							<label
								role="button"
								className="form-check-label text-start text-muted mx-2"
								htmlFor={`checkbox`}
							>
								Mark job in progress & confirmed
							</label>
						</div>
					</div>
					{/* temp comment  */}
					{/* <button disabled={!selectedInvoice} className='btn btn-primary w-100 mt-3' onClick={handleLinkInvoice}>Link Invoice</button> */}
				</Modal>
				<Modal
					title="Update job status"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					btnText="Update"
					trigger="updateJobStatusModal"
					btnClass="updateJobStatusModalClose"
					showBtn={false}
				>
					{job && professionals.length > 0 ? (
						<JobStatusUpdateForm
							uuid={job?.uuid}
							currentStatus={job?.status_id}
							currentProfessional={job?.userpro?.id}
							professionals={professionals}
							setMessage={setMessage}
							reload={() => loadJobByUUID(uuid)}
						/>
					) : null}
				</Modal>
				<Modal
					title="Get help"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="getHelpModal"
					showBtn={false}
					btnClass="getHelpModalClose"
					size="lg"
				>
					<GetHelpForm isTaxPro={LoginData.isTaxPro} />
				</Modal>
				{LoginData.isTaxPro && (
					<Modal
						title="Dispute details"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="createDisputeModal"
						showBtn={false}
						btnClass="createDisputeModalClose"
						size="lg"
					>
						<AddDisputeForm jobId={job.id} reload={() => loadJobByUUID(uuid)} />
					</Modal>
				)}
				<CompleteJobStepPopup
					data={{ ...job, ...{ user: lead.user } }}
					taxProUserId={lead?.userpro?.user?.id || LoginData?.id}
					setOuterMessage={setMessage}
				/>
				<Footer />
			</div>
		);
	}

	return <Preloader />;
};

export default JobDetails;
