import React from 'react';
import Modal from '../../Dashboard/Modal';
import { Link } from 'react-router-dom';
import Input from '../fields/Input';
import DropDown from '../fields/DropDown';
import CalendarComp from '../fields/SingleDatePicker';
import Message from '../fields/Message';

export default function GenerateInvoice({
	message,
	InvoiceName,
	setInvoiceName,
	InvoiceDueDate,
	setInvoiceDueDate,
	InvoicePeriod,
	setInvoicePeriod,
	InvoiceOptions,
	SelectedInvoice,
	setSelectedInvoice,
	InvoicePeriodOptions,
	InvoiceAmount,
	setInvoiceAmount,
	handleSubmit,
	handleUpdate,
	handleCancel,
	leadsOptions,
	SelectedLead,
	setSelectedLead,
	editInvoice,
	jobInvoice,
	jobsOptions,
	selectedService,
	setselectedService,
	SelectedJob,
	setSelectedJob
}) {
	const [open, setOpen] = React.useState(false);
	const today = new Date();
	// Set the minimum date to be tomorrow
	const minDate = new Date(today);
	minDate.setDate(today.getDate() + 1);

	const handleInputAmountChange = (event) => {
		const inputValue = event;
		const regex = /^\d+(\.\d{0,2})?$/; // Allows any number of digits before the decimal point and up to 2 digits after the decimal point
		if (regex.test(inputValue) || inputValue === '') {
			setInvoiceAmount(inputValue);
		}
	};
	var leadjoblist = SelectedLead?.jobs?.filter((data)=>data?.status_id===0 || data?.status_id===1  || data?.status_id===2).map((data) => {
		   return {
			value: data?.service_id !== null ? data?.service?.name : data?.others,
			label: data?.service_id !== null ? data?.service?.name : data?.others,
			job_id:data.id
		};
	});	
	leadjoblist?.push({value: 'create Job',
		label: 'Create Job',})
	return (
		<Modal
			title={editInvoice ? 'Edit Invoice Details' : 'Generate New Invoice'}
			bodyClass="p-4"
			btnText={editInvoice ? 'Edit Invoice Details' : 'Generate New Invoice'}
			trigger="addNewInvoiceModal"
			showBtn={false}
			size={'lg'}
			close={false}
		>
			<form
				className="w-100 d-flex justify-content-center flex-wrap align-item-start"
				onSubmit={(e) => {
					editInvoice ? handleUpdate(e) : handleSubmit(e);
				}}
			>
				<div className="w-100 d-flex align-item-center justify-content-center">
					{message?.show ? <Message message={message} /> : <></>}
				</div>
				{editInvoice ? (
					''
				) : (
					<div className="row w-100 mb-3">
						<DropDown
							options={InvoiceOptions}
							value={SelectedInvoice}
							setValue={setSelectedInvoice}
							label={'Select Invoice Type'}
							showLabel={false}
						/>
					</div>
				)}
				{editInvoice ? (
					''
				) : (
					<fieldset className="w-100 border-top mb-2">
						<legend className="float-none fs-5 px-2 text-start w-auto">
							<small>Invoice Details</small>
						</legend>
					</fieldset>
				)}

				<div className={`mb-2 row w-100`}>
					<div className="mb-3 col-md-6">
						<DropDown
							options={leadsOptions}
							value={SelectedLead}
							setValue={setSelectedLead}
							label="Users List"
							showLabel={false}
							isDisabled={editInvoice || jobInvoice ? true : false}
							onChange={(e) => {
								setSelectedJob('');
							}}
						/>
					</div>

					<div className="mb-3 col-md-6">
						<DropDown
							options={leadjoblist}
							value={SelectedJob}
							setValue={setSelectedJob}
							label="Job List"
							showLabel={false}
							isDisabled={editInvoice || jobInvoice ? true : false}
							onChange={(e) => {
								setInvoiceName(e.value);
							}}
						/>
					</div>
					{/* {!editInvoice && (
						<div className='mb-3 col-md-6'>
							<DropDown
								options={jobsOptions}
								value={selectedService}
								setValue={setselectedService}
								label={'Services'}
								showLabel={false}
								isDisabled={editInvoice ? true : false}
							/>
						</div>
					)} */}
					{selectedService?.value === 'tgp-custom-option' && (
						<div className="w-100 mb-3">
							<Input
								type="text"
								value={InvoiceName}
								name="Name For Invoice"
								placeholder="Name For Invoice"
								showLabel={false}
								fn={setInvoiceName}
							/>
						</div>
					)}
					{SelectedInvoice?.value === 'RecurringPayment' ? (
						<div className='mb-3 col-md-6'>
							<DropDown
								options={InvoicePeriodOptions}
								value={InvoicePeriod}
								setValue={setInvoicePeriod}
								label={'Subscription Duration'}
								showLabel={false}
							/>
						</div>
					) : (
						''
					)}
					<div className='mb-3 col-md-6'>
						<Input
							value={InvoiceAmount}
							name='Amount'
							type={'number'}
							showLabel={false}
							placeholder='Amount'
							fn={handleInputAmountChange}
						/>
					</div>
					<div className={`mb-3 col-md-6`}>
						<CalendarComp
							calendar={InvoiceDueDate}
							setCalendar={setInvoiceDueDate}
							open={open}
							setOpen={setOpen}
							min={minDate}
							childComp={
								<Input
									type="text"
									value={InvoiceDueDate}
									name="Due Date"
									placeholder="Due Date"
									fn={() => {}}
									mask="99/99/9999"
									showLabel={false}
									onClick={() => setOpen((open) => !open)}
								/>
							}
						/>
					</div>
				</div>
				{leadsOptions?.length === 0 ? (
					<div className="w-100 d-flex align-items-center justify-content-center">
						<p className="smallnote text-primary mt-0 mb-0 text-red">
							You don't have any jobs. Go to jobs tab and add jobs to proceed!
						</p>
					</div>
				) : (
					''
				)}
				{SelectedInvoice?.value === 'PartialPayment' ? (
					<div className="w-100 d-flex align-items-center justify-content-center">
						<p className="smallnote text-muted  mt-0 mb-0">
							You will be charged 40% percent at the start of the work and the balance after the
							work is done.
						</p>
					</div>
				) : (
					''
				)}
				<div className="container-fluid content-row">
					<div className="d-flex align-items-center justify-content-center row">
						<div className={'col-sm-4 custon-button mt-4'}>
							<Link
								to="#"
								className="d-block text-decoration-none text-dark invoiceModal"
								data-bs-toggle="modal"
								data-bs-target="#addNewInvoiceModal"
							>
								<button
									className={'btn btn-info custon-button-text text-white btn-lg w-100'}
									type={'button'}
									onClick={() => {
										handleCancel();
									}}
								>
									Cancel
								</button>
							</Link>
						</div>
						<div className={'col-sm-4 custon-button  mt-4'}>
							<button
								id="paymentSubmit"
								className={'btn btn-primary custon-button-text btn-lg w-100'}
								type="submit"
							>
								{editInvoice ? 'Update' : SelectedJob?.value==='create Job'?'Create Job':'Generate'}
							</button>							
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
}
